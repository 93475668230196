import { StepperGlobal } from '@app/shared/dto/stepper.dto';
import { marker as _ } from '@jsverse/transloco-keys-manager/marker';

export enum StepperTwoFactorEnum {
  QR = 'QR',
  CONFIRMATION = 'CONFIRMATION',
  VERIFIED = 'VERIFIED',
  DONE = 'DONE',
}

export const STEPPER_DATA: StepperGlobal<StepperTwoFactorEnum>[] = [
  {
    title: _('two-factor.stepper.step-1'),
    selector: StepperTwoFactorEnum.QR,
    completed: false,
  },
  {
    title: _('two-factor.stepper.step-2'),
    selector: StepperTwoFactorEnum.CONFIRMATION,
    completed: false,
  },
  {
    title: _('two-factor.stepper.step-3'),
    selector: StepperTwoFactorEnum.VERIFIED,
    completed: false,
  },
];
